import { normalizeValue } from '../strings/normalizeValue';

export function isBlankValue(value: unknown): boolean {
	// Normalize strings
	if (typeof value === 'string') {
		// See if the string normalizes to an empty value
		const normalized = normalizeValue(value);
		if (!normalized) {
			return true;
		}
	}

	// Ignore anything undefined
	if (value === null || value === undefined) {
		return true;
	}

	return false;
}

export function isNotBlankValue(value: unknown): boolean {
	return !isBlankValue(value);
}
