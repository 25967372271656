'use client';

import {
	type AccountInfo,
	AzureCloudInstance,
	type Configuration,
	type ILoggerCallback,
	InteractionType,
	LogLevel,
	Logger,
	type RedirectRequest,
	type SilentRequest,
} from '@azure/msal-browser';
import type { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { useMemo } from 'react';
import { useCurrentURL } from '../../app/(client)/(authenticated)/jobs/(jobId)/jobLink/useCurrentURL';
import { useAppInsights } from '../../components/AppInsightsWrapper/AppInsightsWrapper';
import { logDebug, logError, logInfo, logWarning } from './logging/writeLog';

// Map different trace levels to app insight logging functions
const traceLevels: Record<
	number,
	(appInsights: ReactPlugin, text: string, properties?: Record<string, unknown>) => void
> = {};
traceLevels[LogLevel.Error] = logError;
traceLevels[LogLevel.Warning] = logWarning;
traceLevels[LogLevel.Info] = logInfo;
traceLevels[LogLevel.Verbose] = logDebug;

const tenantId = process.env.NEXT_PUBLIC_AZURE_TENANT_ID;
const clientId = process.env.NEXT_PUBLIC_AZURE_CLIENT_ID;
const redirectUri = process.env.NEXT_PUBLIC_AZURE_REDIRECT_URI;

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
	auth: {
		azureCloudOptions: {
			azureCloudInstance: AzureCloudInstance.AzurePublic,
			tenant: tenantId,
		},
		clientId: clientId,
		redirectUri: redirectUri,
		navigateToLoginRequestUrl: true,
	},
	cache: {
		cacheLocation: 'sessionStorage',
	},
	system: {
		loggerOptions: {
			logLevel: LogLevel.Error,
		},
	},
};

export function useMsalLogger(): Logger {
	const appInsights = useAppInsights();

	const msalLogger: Logger = useMemo(() => {
		const loggingCallback: ILoggerCallback = (level: LogLevel, message: string, containsPii: boolean) => {
			if (containsPii) {
				return;
			}
			let logger = traceLevels[level];
			if (!logger) {
				logger = console.log;
			}
			logger(appInsights, message);
		};
		const logger = new Logger({
			loggerCallback: loggingCallback,
			piiLoggingEnabled: false,
			logLevel: LogLevel.Error,
		});
		return logger;
	}, [appInsights]);

	return msalLogger;
}

export function useRedirectRequest(): RedirectRequest {
	const url = useCurrentURL();
	// const [request, setRequest] = useState<RedirectRequest>();

	const request = useMemo(() => {
		const newRequest: RedirectRequest = {
			scopes: ['openid'],
			extraQueryParameters: { domain_hint: 'dteenergy.com' },

			// Come back to where we are
			redirectStartPage: url,
		};
		return newRequest;
		// setRequest(newRequest);
	}, [url]);

	return request;
}

// Which type of login we want to use as a fallback
export function getLoginMethod(): InteractionType {
	return InteractionType.Redirect;
}

/**
 * Defaults for getting a silent token request
 *
 * @param account
 * @param forceRefresh
 * @returns
 */
export function getSilentRequest(account: AccountInfo, forceRefresh?: boolean): SilentRequest {
	const tokenRequest: SilentRequest = {
		scopes: ['openid'],
		extraQueryParameters: { domain_hint: 'dteenergy.com' },
		account: account,
		forceRefresh: forceRefresh,
	};
	return tokenRequest;
}
