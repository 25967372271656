'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';

// TODO: this should take advantage of our common logic for checking same pathname / params

export function useCurrentURL(): string {
	const pathname = usePathname();
	const searchParams = useSearchParams();

	const [currentURL, setCurrentURL] = useState<string>();

	useEffect(() => {
		let newURL = `${pathname}`;
		if (!newURL.endsWith('/')) {
			newURL += '/';
		}
		const params = searchParams?.toString();
		if (params) {
			newURL += `?${params}`;
		}
		setCurrentURL(newURL);
	}, [pathname, searchParams]);

	return currentURL;
}
