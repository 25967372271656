import { isAfter as dateFnsAfter } from 'date-fns';
import { safeParseDate } from '../safeParseDate';

/**
 * Return true if dateA is after dateB
 *
 * @param comesAfter
 * @param compareAgainst
 * @returns
 */
export function isAfter(comesAfter: string | Date, compareAgainst: string | Date): boolean {
	const dateAfter = safeParseDate(comesAfter);
	if (!dateAfter) {
		return false;
	}
	const dateToCompare = safeParseDate(compareAgainst);
	if (!dateToCompare) {
		return false;
	}

	return dateFnsAfter(dateAfter, dateToCompare);
}
