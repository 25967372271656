export function safeParseInt(value: string | number, defaultValue?: number): number {
	let parsed: number;

	// Attempt to parse
	try {
		// Value is already a number
		if (typeof value === 'number') {
			// Mimic the value of parseInt and round down
			parsed = Math.floor(value);
		} else {
			parsed = Number.parseInt(value, 10);
		}
	} catch {
		parsed = undefined;
	}

	// Set to undefined if the value isn't a number
	if (Number.isNaN(parsed)) {
		parsed = undefined;
	}

	// Replace with the default value if one is defined
	if (parsed === undefined && defaultValue !== undefined) {
		parsed = defaultValue;
	}

	return parsed;
}
