import { isEmpty } from '../objects/isEmpty';
import { upperCaseValue } from './upperCaseValue';

export function equalsIgnoreCase(valueA: string, valueB: string): boolean {
	const upperA = upperCaseValue(valueA);
	const upperB = upperCaseValue(valueB);
	return upperA === upperB;
}

export function containsIgnoreCase(list: string[], value: string): boolean {
	if (isEmpty(list)) {
		return false;
	}

	for (const listValue of list) {
		if (equalsIgnoreCase(listValue, value)) {
			return true;
		}
	}

	return false;
}

export function containsAnyIgnoreCase(list: string[], values: string[]): boolean {
	if (isEmpty(list)) {
		return false;
	}

	if (isEmpty(values)) {
		return false;
	}

	for (const value of values) {
		const containsValue = containsIgnoreCase(list, value);
		if (containsValue) {
			return true;
		}
	}

	return false;
}
