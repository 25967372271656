import { safeParseInt } from '../numbers/safeParseInt';
import { isBlankValue } from '../objects/isBlankValue';
import { containsIgnoreCase } from './equalsIgnoreCase';

export function isTruthy(value: string | boolean | number): boolean {
	// For boolean values, just return the value as-is
	if (value === true) {
		return true;
	}
	if (value === false) {
		return false;
	}

	// Treat blank values as false
	if (isBlankValue(value)) {
		return false;
	}

	// Treat strings as "true" if they have a value of "true" (case-insensitive)
	if (typeof value === 'string') {
		if (containsIgnoreCase(['true', 't'], value)) {
			return true;
		}
	}

	// For numbers (or strings we're able to parse as numbers), consider as "true" if greater than 0
	const numericValue = safeParseInt(value);
	if (numericValue > 0) {
		return true;
	}

	return false;
}
